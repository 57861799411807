import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第二輪</h2>
          <h1 className="main_title">#機制執行</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_8_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：林宜珍<br/>組員：吳思璇、姚仲涵、黃金樺</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>這輪延續上一組對法人營運的討論，探討是否有更細緻的運行模式。</p>

                <h4>1. 時間性與延續性</h4>
                <p>公共藝術的維護在設置案中雖然有相關規定，但是常遇到公部門未編列預算、無心維護的困境，反將問題拋回藝術家，行政流程效力不彰。</p>
                <p>法人組織是否可營運公共藝術基金，並擔任政府與社宅藝術家的中介機構？讓法人從前端整合，參與作品規劃、設置到維護過程，解決作品時間性與延續性的問題。</p>

                <h4>2. 賦權地方組織</h4>
                <p>前述法人的中央管理型態下，能否進一步照顧到地方自明性？若能和在地組織合作，讓他們參與作品生產過程，對公共藝術作品產生連結與認同，進一步擔任維護角色。透過向下賦權，從田調、創作、維護都回到地方，照顧到各基地的地方性與自明性。</p>

                <h4>討論補充</h4>
                <p>1. 賦權地方參與公共藝術，也需要專職美學管理的單位，才不會流於一般社區營造的樣貌。據知臺北社宅有開放青創戶入住名額，是否能由這群有美學、設計涵養的住戶擔任此角色？</p>
                <p>2. 除了上述方式，引入參與式設計，由藝術家主責創作、居民加入設計的過程，可以平衡美學的掌握與地方參與認同。</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_9" className="next_page">
            <div className="next_title">#城市策略</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第二輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
